import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../dto/table.dto';

export const associatedControlListConfig: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'control_code',
    header: 'Control Code',
    sortable: true,
    size: DataCellSize.Code,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Control Title',
    sortable: true,
    size: DataCellSize.Title,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'control_type_title',
    header: 'Control Type',
    sortable: true,
    size: DataCellSize.Category,
    limit: 50,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'control_category_title',
    header: 'Control Category',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'control_implementation_status',
    header: 'Implementation',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'control_efficiency_status',
    header: 'Efficency',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Datetime,
    field: 'last_tested_at',
    header: 'Last Tested At',
    sortable: true,
    size: DataCellSize.DateTime,
    isEnable: true,
    isRequired: true,
  },
];
