import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../core/store/current-user-preference.store';
import { TableConversion } from './table.conversion';
import {
  ComplianceAssociatedListItemDTO,
  ComplianceRequirementAssociatedListItemDTO,
  ControlAssociatedItemListDTO,
  IncidentAssociatedItemListDTO,
  ProcessAssociatedItemListDTO,
  RiskAssociatedItemListDTO,
  SlaAssociatedItemListDTO,
  StrategicObjectiveAssociatedItemListDTO,
} from '../dto/associated-item.dto';
import { UserConversion } from '../../features/organization/users/conversion/user.conversion';

@Injectable({
  providedIn: 'root',
})
export class AssociatedItemConversion {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToProcessAssociatedItemListDTO(response: ProcessAssociatedItemListDTO[]) {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    return response.map((item: any) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        process_group_title: item[`process_group_title_${this.lang}`],
        title: item[`title_${this.lang}`],
        responsible_unit_title: item[`owner_unit_title_${this.lang}`],
        owner: this.userConv.resToUserMinimalDTO(item, 'owner'),
        status: this.tableConv.resToColordItem(item, 'lifecycle_status'),
      };
    });
  }
  /**
   * Convert response in to associated control list dto
   * @param response
   * @returns
   */
  resToControlAssociatedItemListDTO(
    response: any[]
  ): ControlAssociatedItemListDTO[] {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    return response.map((item: any) => {
      return {
        control_id: item.id,
        control_code: item.control_code,
        title: item[`control_title_${this.lang}`],
        control_type_title: item[`control_type_title_${this.lang}`],
        control_category_title: item[`control_category_title_${this.lang}`],
        control_implementation_status: this.tableConv.resToColordItem(
          item,
          'control_implementation_status'
        ),
        control_efficiency_status: this.tableConv.resToColordItem(
          item,
          'control_efficiency_status'
        ),
        last_tested_at: item.control_last_test_date,
      };
    });
  }

  resToStrategicObjectiveAssociatedItemListDTO(
    response: any[]
  ): StrategicObjectiveAssociatedItemListDTO[] {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    return response.map((item: any) => {
      return {
        strategic_objective_status: item.strategic_objective_status,
        strategic_objective_title:
          item[`strategic_objective_title_${this.lang}`],
      };
    });
  }

  resToSlaAssociatedItemListDTO(
    response: any[]
  ): SlaAssociatedItemListDTO[] {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    return response.map((item: any) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        title: item[`title_${this.lang}`],
        description: item.description,
        client: item.client,
        sla_category:  item[`sla_category_title_${this.lang}`],
        responsible_unit: item[`responsible_unit_title_${this.lang}`],
        responsible_user: this.userConv.resToUserMinimalDTO(item, 'responsible_user'),
        severity_level: this.tableConv.resToColordItem(item, 'severity_level'),
        lifecycle_status: this.tableConv.resToColordItem(item, 'lifecycle_status'),
      };
    });
  }

  resToIncidentAssociatedItemListDTO(
    response: any[]
  ): IncidentAssociatedItemListDTO[] {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    return response.map((item: any) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        title: item.title,
        description: item.description,
        incident_at: item.incident_at,
        location: item.location,
        reported_at: item.reported_at,
        quick_action: item.quick_action,
        incident_category:  item[`incident_category_title_${this.lang}`],
        incident_sub_category: item[`incident_sub_category_title_${this.lang}`],
        incident_type: item[`incident_type_title_${this.lang}`],
        severity_level: this.tableConv.resToColordItem(item, 'severity_level'),
        incident_status: this.tableConv.resToColordItem(item, 'incident_status'),
      };
    });
  }

  resToRiskAssociatedItemListDTO(
    response: any[]
  ): RiskAssociatedItemListDTO[] {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    return response.map((item: any) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        risk_category:  item[`risk_category_title_${this.lang}`],
        risk_source: item[`risk_source_title_${this.lang}`],
        owner_unit: item[`owner_unit_title_${this.lang}`],
        owner: this.userConv.resToUserMinimalDTO(item, 'owner'),
        title: item.title,
        description: item.description,
        risk_status: this.tableConv.resToColordItem(item, 'risk_status'),
      };
    });
  }


  resToComplianceRequirementAssociatedItemListDTO(
    requirement: any[]
  ): ComplianceRequirementAssociatedListItemDTO[] {
    if (!requirement || !Array.isArray(requirement)) {
      return [];
    }
    return requirement.map((item: any) => {
      return {
        compliance_requirement_id: item.compliance_requirement_id,
        compliance_clause_title: item.compliance_clause_title,
        compliance_clause_no: item.compliance_clause_no,
        compliance_requirement_category:  item[`compliance_requirement_category_title_${this.lang}`],
        compliance_requirement_title: item.compliance_requirement_title,
        compliance_status: this.tableConv.resToColordItem(item, 'compliance_status'),
      };
    });
  }

  resToComplianceAssociatedItemListDTO(
    response: any[]
  ): ComplianceAssociatedListItemDTO[] {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    return response.map((item: any) => {
      return {
        compliance_register_id: item.compliance_register_id,
        compliance_register:  item[`compliance_register_title_${this.lang}`],
        requirements: this.resToComplianceRequirementAssociatedItemListDTO(item.requirements || []),
      };
    });
  }


  resToTrnComplianceAssociatedItemListDTO(response: any[]): any[] {
    if (!response || !Array.isArray(response)) {
      return [];
    }
    const complianceItems = this.resToComplianceAssociatedItemListDTO(response);
    const result: any[] = [];
    complianceItems.forEach((compliance) => {
      const { compliance_register, requirements } = compliance;
      const rowspan = requirements.length;

      requirements.forEach((requirement: any, index: number) => {
        if (index === 0) {
          result.push({
            compliance_register: compliance_register,
            compliance_clause_title: requirement.compliance_clause_title,
            rowspan,
            compliance_requirement_category: requirement.compliance_requirement_category,
            compliance_requirement_title: `${requirement.compliance_clause_no} - ${requirement.compliance_requirement_title}`,
            compliance_status: requirement.compliance_status,
          });
        } else {
          result.push({
            compliance_requirement_category: requirement.compliance_requirement_category,
            compliance_requirement_title: `${requirement.compliance_clause_no} - ${requirement.compliance_requirement_title}`,
            compliance_status: requirement.compliance_status,
          });
        }
      });
    });
    return result;
  }

 
}
